import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

const metadata = {
  title: "Connect Woocommerce to ReferralCandy",
}

const WoocommerceSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for Woocommerce</h1>
        <p className={fontType.b1}>
          Step 1: Sign up for a new ReferralCandy account (1 min)
        </p>
        <p>
          Head over to the{" "}
          <a href="https://my.referralcandy.com/signup#woocommerce">
            sign up page
          </a>{" "}
          to register a new ReferralCandy account for your WooCommerce store.
        </p>
        <p className={fontType.b1}>
          Step 2: Complete our setup wizard (around 5 mins)
        </p>
        <p>
          After you sign up, our setup wizard will take you through the
          necessary steps to configure your referral campaign. Don't worry about
          getting everything finalized in the first pass, you can always edit
          the settings again later, before you activate the campaign.{" "}
          <span role="img" aria-label="happy face">
            🙂
          </span>
        </p>
        <p className={fontType.b1}>
          Step 3: Install and configure the WooCommerce plugin (2 mins)
        </p>
        <p>
          Install the{" "}
          <a href="https://wordpress.org/plugins/referralcandy-for-woocommerce/installation/">
            ReferralCandy for WooCommerce
          </a>{" "}
          plugin and follow the instructions to configure the plugin for your
          ReferralCandy account.
        </p>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default WoocommerceSetup
